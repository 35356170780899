import React from "react";
import AdminLTE, { Footer, Navbar, Sidebar } from "adminlte-2-react";
import NavbarMenu from "./components/NavbarMenu";
import SidebarMenu from "./components/SidebarMenu";

function PrivateLayout({ children }) {
  return (
    <AdminLTE
      title={["ASTRO", "PMS"]}
      titleShort={["", "PMS"]}
      theme="black"
      footer={
        <Footer includeVersionInfo={false}>
          <span
            style={{
              textAlign: "right",
              position: "absolute",
              right: "10px",
            }}
          >
            <b>ASTRO</b>PMS © 2024
          </span>
        </Footer>
      }
    >
      <Navbar.Core>
        <NavbarMenu />
      </Navbar.Core>
      <Sidebar.Core>
        <SidebarMenu />
      </Sidebar.Core>
      {children}
    </AdminLTE>
  );
}

export default PrivateLayout;
