import Echo from "laravel-echo";
import Pusher from "pusher-js";

const initializeEcho = () => {
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    wsHost:
      process.env.NODE_ENV === "development"
        ? window.location.hostname
        : process.env.REACT_APP_WS_HOST,
    wsPort: 6001,
    wssPort: 443,
    forceTLS: process.env.REACT_APP_FORCE_TLS === "true",
    enabledTransports: ["ws", "wss"],
    disableStats: true,
  });

  // console.log if it's connected
  window.Echo.connector.pusher.connection.bind("connected", () => {
    console.log("Connected to Pusher");
  });
};

export default initializeEcho;
