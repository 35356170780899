import VesselOwner from "./VesselOwner";

export default function Vessel(data = {}) {
  this.id = data.id ? data.id : 0;
  this.code_name = data.code_name ? data.code_name : "";
  this.name = data.name ? data.name : "";
  this.owner = data.owner ? new VesselOwner(data.owner) : new VesselOwner();
  this.former_name = data.former_name ? data.former_name : "";
  this.flag = data.flag ? data.flag : "";
  this.call_sign = data.call_sign ? data.call_sign : "";
  this.official_no = data.official_no ? data.official_no : "";
  this.imo_no = data.imo_no ? data.imo_no : "";
  this.loa = data.loa ? data.loa : "";
  this.lbp = data.lbp ? data.lbp : "";
  this.light_condition = data.light_condition ? data.light_condition : "";
  this.classification = data.classification ? data.classification : "";
  this.character = data.character ? data.character : "";
  this.descriptive_note = data.descriptive_note ? data.descriptive_note : "";
  this.built_year = data.built_year ? data.built_year : "";
  this.build_yard = data.build_yard ? data.build_yard : "";
  this.tpc = data.tpc ? data.tpc : "";
  this.breadth = data.breadth ? data.breadth : "";
  this.depth = data.depth ? data.depth : "";
  this.summer_draft = data.summer_draft ? data.summer_draft : "";
  this.summer_freeboard = data.summer_freeboard ? data.summer_freeboard : "";
  this.summer_deadweight = data.summer_deadweight ? data.summer_deadweight : "";
  this.winter_draft = data.winter_draft ? data.winter_draft : "";
  this.winter_freeboard = data.winter_freeboard ? data.winter_freeboard : "";
  this.winter_deadweight = data.winter_deadweight ? data.winter_deadweight : "";
  this.tropical_draft = data.tropical_draft ? data.tropical_draft : "";
  this.tropical_freeboard = data.tropical_freeboard
    ? data.tropical_freeboard
    : "";
  this.tropical_deadweight = data.tropical_deadweight
    ? data.tropical_deadweight
    : "";
  this.tropical_fw_draft = data.tropical_fw_draft ? data.tropical_fw_draft : "";
  this.tropical_fw_freeboard = data.tropical_fw_freeboard
    ? data.tropical_fw_freeboard
    : "";
  this.tropical_fw_deadweight = data.tropical_fw_deadweight
    ? data.tropical_fw_deadweight
    : "";
  this.fw_draft = data.fw_draft ? data.fw_draft : "";
  this.fw_freeboard = data.fw_freeboard ? data.fw_freeboard : "";
  this.fw_deadweight = data.fw_deadweight ? data.fw_deadweight : "";
  this.fw_allowance = data.fw_allowance ? data.fw_allowance : "";
  this.light_shift_drafts_f = data.light_shift_drafts_f
    ? data.light_shift_drafts_f
    : "";
  this.light_shift_drafts_a = data.light_shift_drafts_a
    ? data.light_shift_drafts_a
    : "";
  this.heavy_ballast_drafts_f = data.heavy_ballast_drafts_f
    ? data.heavy_ballast_drafts_f
    : "";
  this.heavy_ballast_drafts_a = data.heavy_ballast_drafts_a
    ? data.heavy_ballast_drafts_a
    : "";
  this.normal_ballast_drafts_f = data.normal_ballast_drafts_f
    ? data.normal_ballast_drafts_f
    : "";
  this.normal_ballast_drafts_a = data.normal_ballast_drafts_a
    ? data.normal_ballast_drafts_a
    : "";
  this.international_gt = data.international_gt ? data.international_gt : "";
  this.international_nt = data.international_nt ? data.international_nt : "";
  this.panama_gt = data.panama_gt ? data.panama_gt : "";
  this.panama_nt = data.panama_nt ? data.panama_nt : "";
  this.suez_gt = data.suez_gt ? data.suez_gt : "";
  this.suez_nt = data.suez_nt ? data.suez_nt : "";
}
