let onlineStatus = navigator.onLine;

window.addEventListener("online", () => {
  onlineStatus = true;
});

window.addEventListener("offline", () => {
  onlineStatus = false;
});

export function getStatus() {
  if (!onlineStatus) {
    console.log("Device is offline");
    return Promise.resolve(false);
  }

  const url = `${process.env.REACT_APP_API_URL}status`;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    const timeout = 3000; // 3 seconds
    const timer = setTimeout(() => {
      xhr.abort();
      resolve(false); // Server is assumed to be offline if request times out
      localStorage.setItem("isOnline", JSON.stringify(false));
    }, timeout);

    xhr.open("HEAD", url, true); // Use HEAD method to only get headers

    xhr.onload = () => {
      clearTimeout(timer);
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(true); // Server is online
      } else {
        resolve(false); // Server responded with an error status
        localStorage.setItem("isOnline", JSON.stringify(false));
      }
    };

    xhr.onerror = () => {
      clearTimeout(timer);
      resolve(false); // Server is not reachable
      localStorage.setItem("isOnline", JSON.stringify(false));
    };

    xhr.send();
  });
}
