import Http from "../utils/Http";

export function login(credentials) {
  const config = {
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  };

  const data = { ...credentials, ...config };

  return new Promise((resolve, reject) => {
    Http.post("oauth/token", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(
          err?.response?.data?.message ||
            "Something went wrong. Please try again."
        );
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    Http.delete("oauth/token")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function forgotPassword(data) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  formData.append("_method", "POST");
  return new Promise((resolve, reject) => {
    Http.post("oauth/password/forgot", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPassword(data) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  formData.append("_method", "POST");
  return new Promise((resolve, reject) => {
    Http.post("oauth/password/reset", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data.error;

        if (typeof error === "string") {
          if (error === "Invalid/Expired Password Reset Token.") {
            reject(
              "Your password reset link is invalid or has expired. Please request a new password reset."
            );
          }

          reject("Something went wrong. Please try again.");
        }

        const errorObject = error;

        let errorMessage = "";
        for (const key in errorObject) {
          if (errorObject.hasOwnProperty(key)) {
            errorMessage += `\n${errorObject[key]}`;
          }
        }

        reject(errorMessage);
      });
  });
}
