import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VesselMachineryRunningHour from "../core/models/VesselMachineryRunningHour";
import Meta from "../core/models/Meta";
import {
  add,
  fetchAll,
  exportRunningHours,
  exportRunningHoursHistory,
} from "../services/runningHourService";
import Transform from "../utils/Transformer";
import db from "../db";
import moment from "moment";
import { v4 as uuid } from "uuid";

const FileDownload = require("js-file-download");

const initialState = {
  data: new VesselMachineryRunningHour(),
  list: [],
  meta: new Meta(),
  listStatus: "idle",
  dataStatus: "idle",
};

export const runningHourListAsync = createAsyncThunk(
  "runningHour/fetchAllRunningHours",
  async (params) => {
    const { isOnline, ...parameters } = params;

    if (!isOnline) {
      let {
        page = 1,
        limit = 20,
        department,
        start_date,
        end_date,
        keyword,
      } = parameters;

      let baseQuery = db.runningHours;

      if (department) {
        baseQuery = baseQuery.filter(
          (runningHour) => runningHour.machinery.department.name === department
        );
      }

      if (start_date && end_date) {
        const startDate = moment(start_date).startOf("day");
        const endDate = moment(end_date).endOf("day");

        baseQuery = baseQuery.filter((runningHour) => {
          if (!runningHour.current_running_hour) return false;
          const createdAt = moment(
            runningHour.current_running_hour.created_at,
            "DD-MMM-YYYY"
          );
          return createdAt.isBetween(startDate, endDate, null, "[]");
        });
      } else {
        if (start_date) {
          const startDate = moment(start_date).startOf("day");

          baseQuery = baseQuery.filter((runningHour) => {
            if (!runningHour.current_running_hour) return false;
            const createdAt = moment(
              runningHour.current_running_hour.created_at,
              "DD-MMM-YYYY"
            );
            return createdAt.isSameOrAfter(startDate);
          });
        }

        if (end_date) {
          const endDate = moment(end_date).endOf("day");

          baseQuery = baseQuery.filter((runningHour) => {
            if (!runningHour.current_running_hour) return false;
            const createdAt = moment(
              runningHour.current_running_hour.created_at,
              "DD-MMM-YYYY"
            );
            return createdAt.isSameOrBefore(endDate);
          });
        }
      }

      if (keyword) {
        baseQuery = baseQuery.filter((runningHour) => {
          const lowerKeyword = keyword.toLowerCase();

          const vesselName = (runningHour.vessel.name ?? "").toLowerCase();
          const machineryName = (
            runningHour.machinery.name ?? ""
          ).toLowerCase();
          const codeName = (
            runningHour.machinery.code_name ?? ""
          ).toLowerCase();
          const runningHours =
            runningHour.current_running_hour?.running_hours ?? 0;

          return (
            vesselName.includes(lowerKeyword) ||
            machineryName.includes(lowerKeyword) ||
            codeName.includes(lowerKeyword) ||
            runningHours.toString().includes(lowerKeyword)
          );
        });
      }

      const totalFilteredCount = await baseQuery.count();
      const paginatedQuery = baseQuery.offset((page - 1) * limit).limit(limit);
      const data = await paginatedQuery.toArray();

      const meta = new Meta({
        total: totalFilteredCount,
        current_page: page,
        last_page: Math.ceil(totalFilteredCount / limit),
        per_page: limit,
        previous_page_url: "",
        next_page_url: "",
        url: "",
      });

      return { data, meta };
    }

    const response = await fetchAll(parameters);
    const data = Transform.fetchCollection(
      response.data,
      VesselMachineryRunningHour
    );
    const meta = Transform.fetchObject(response.meta, Meta);
    return { data, meta };
  }
);

export const runningHourAddAsync = createAsyncThunk(
  "runningHour/addNewRunningHours",
  async (params) => {
    const { isOnline, ...parameters } = params;

    if (!isOnline) {
      try {
        const { vessel_machinery_id, running_hours, updating_date } =
          parameters;

        const formatted_updating_date = moment(updating_date)
          .format("DD-MMM-YYYY")
          .toString();
        const formatted_running_hours = (+running_hours).toFixed(2);

        const profile = await db.profile.toCollection().first();
        const creator = profile
          ? `${profile.first_name} ${profile.last_name}`
          : "Offline";

        await db.queue.add({
          method: "POST",
          request_url: "running-hours",
          data: {
            ...parameters,
            created_at: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
            updated_at: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
          },
          timestamp: new Date().toISOString(),
        });

        const id = uuid();
        await db.runningHours
          .where("id")
          .equals(vessel_machinery_id)
          .modify((runningHour) => {
            runningHour.running_hour_history.unshift({
              id,
              running_hours: formatted_running_hours,
              updating_date: formatted_updating_date,
              created_at: moment().format("DD-MMM-YYYY").toString(),
              creator,
            });

            const latest = runningHour.running_hour_history.reduceRight(
              (prev, current) =>
                moment(prev.updating_date).isAfter(current.updating_date)
                  ? prev
                  : current
            );

            runningHour.current_running_hour = latest;
          });
      } catch (error) {
        console.error(error);
      }

      return new Meta({
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 20,
        previous_page_url: null,
        next_page_url: null,
        url: "",
      });
    }

    const response = await add(parameters);
    return Transform.fetchObject(response.data, Meta);
  }
);

export const runningHoursExportAsync = createAsyncThunk(
  "runningHour/exportRunningHours",
  async (params) => {
    const runningHours = await exportRunningHours(params);
    FileDownload(runningHours, "Running Hours.xls");
    return runningHours;
  }
);

export const runningHourHistoryExportAsync = createAsyncThunk(
  "runningHour/exportRunningHoursHistory",
  async (id) => {
    const runningHoursHistory = await exportRunningHoursHistory(id);
    FileDownload(runningHoursHistory, "Running Hours History.xls");
    return runningHoursHistory;
  }
);

export const runningHourSlice = createSlice({
  name: "runningHour",
  initialState,
  reducers: {
    resetRunningHour: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(runningHourListAsync.pending, (state) => {
        state.listStatus = "loading";
      })
      .addCase(runningHourListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        state.list = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(runningHourListAsync.rejected, (state, action) => {
        state.listStatus = "idle";
      })
      .addCase(runningHourAddAsync.pending, (state) => {
        state.dataStatus = "loading";
      })
      .addCase(runningHourAddAsync.fulfilled, (state, action) => {
        state.dataStatus = "idle";
        state.data = action.payload;
      })
      .addCase(runningHourAddAsync.rejected, (state, action) => {
        state.dataStatus = "idle";
      });
  },
});

export const { resetRunningHour } = runningHourSlice.actions;

export const runningHourData = (state) => state.runningHour.data;
export const runningHourList = (state) => state.runningHour.list;
export const runningHourMeta = (state) => state.runningHour.meta;
export const reqListStatus = (state) => state.runningHour.listStatus;
export const reqDataStatus = (state) => state.runningHour.dataStatus;

export default runningHourSlice.reducer;
