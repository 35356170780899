import db from ".";

export function clearAllData() {
  db.runningHours.clear();
  db.works.clear();
  db.departments.clear();
  db.machineries.clear();
  db.profile.clear();
  db.queue.clear();
}
