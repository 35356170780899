import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStatus } from "../services/statusService";

const initialState = {
  isOnline: localStorage.getItem("isOnline")
    ? JSON.parse(localStorage.getItem("isOnline")) !== false
    : navigator.onLine,
};

export const statusAsync = createAsyncThunk("status", async () => {
  try {
    const isOnline = await getStatus();
    return isOnline;
  } catch (error) {
    console.error("Error in statusAsync:", error);
    return false;
  }
});

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(statusAsync.fulfilled, (state, action) => {
        state.isOnline = action.payload;
        localStorage.removeItem("isOnline");
      })
      .addCase(statusAsync.rejected, (state, action) => {
        state.isOnline = false;
      });
  },
});

export const { setIsOnline } = statusSlice.actions;

export const isOnlineStatus = (state) => state.status.isOnline;

export default statusSlice.reducer;
