import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VesselMachinerySubCategoryWork from "../core/models/VesselMachinerySubCategoryWork";
import Meta from "../core/models/Meta";
import {
  add,
  count,
  fetchAll,
  exportWorks,
  exportWorkHistory,
  downloadFile,
} from "../services/workService";
import Transform from "../utils/Transformer";
import db from "../db";
import moment from "moment";
import { v4 as uuid } from "uuid";

const FileDownload = require("js-file-download");

const initialState = {
  count: {
    warning: 0,
    due: 0,
    overdue: 0,
    dry_dock: 0,
    jobs_done: 0,
  },
  doneList: [],
  list: [],
  meta: new Meta(),
  countStatus: "idle",
  doneListStatus: "idle",
  listStatus: "idle",
};

export const workListAsync = createAsyncThunk(
  "work/fetchAllWorks",
  async (params) => {
    const { isOnline, ...parameters } = params;

    if (!isOnline) {
      let {
        page = 1,
        limit = 20,
        department,
        machinery,
        status,
        start_date,
        end_date,
        keyword,
      } = parameters;

      let baseQuery = db.works;

      if (department) {
        baseQuery = baseQuery.filter((work) => work.department === department);
      }

      if (machinery) {
        baseQuery = baseQuery.filter((work) => work.machinery === machinery);
      }

      if (status) {
        baseQuery = baseQuery.filter((work) => work.status === status);
      }

      if (start_date && end_date) {
        const startDate = moment(start_date).startOf("day");
        const endDate = moment(end_date).endOf("day");

        baseQuery = baseQuery.filter((work) => {
          if (!work.current_work.created_at) return false;
          const createdAt = moment(work.current_work.created_at, "DD-MMM-YYYY");
          return createdAt.isBetween(startDate, endDate, null, "[]");
        });
      } else {
        if (start_date) {
          const startDate = moment(start_date).startOf("day");

          baseQuery = baseQuery.filter((work) => {
            if (!work.current_work.created_at) return false;
            const createdAt = moment(
              work.current_work.created_at,
              "DD-MMM-YYYY"
            );
            return createdAt.isSameOrAfter(startDate);
          });
        }

        if (end_date) {
          const endDate = moment(end_date).endOf("day");

          baseQuery = baseQuery.filter((work) => {
            if (!work.current_work.created_at) return false;
            const createdAt = moment(
              work.current_work.created_at,
              "DD-MMM-YYYY"
            );
            return createdAt.isSameOrBefore(endDate);
          });
        }
      }

      if (keyword) {
        baseQuery = baseQuery.filter((work) => {
          const lowerKeyword = keyword.toLowerCase();

          const code = (work.code ?? "").toLowerCase();
          const subCategory = (work.sub_category?.name ?? "").toLowerCase();
          const description = (work.description?.name ?? "").toLowerCase();
          const interval = (work.interval?.name ?? "").toLowerCase();
          const instructions = (
            work.current_work?.instructions ?? ""
          ).toLowerCase();
          const remarks = (work.current_work?.remarks ?? "").toLowerCase();

          return (
            code.includes(lowerKeyword) ||
            subCategory.includes(lowerKeyword) ||
            description.includes(lowerKeyword) ||
            interval.includes(lowerKeyword) ||
            instructions.includes(lowerKeyword) ||
            remarks.includes(lowerKeyword)
          );
        });
      }

      const totalFilteredCount = await baseQuery.count();
      const paginatedQuery = baseQuery.offset((page - 1) * limit).limit(limit);
      const data = await paginatedQuery.toArray();

      const meta = new Meta({
        total: totalFilteredCount,
        current_page: page,
        last_page: Math.ceil(totalFilteredCount / limit),
        per_page: limit,
        previous_page_url: "",
        next_page_url: "",
        url: "",
      });

      return { data, meta };
    }

    const response = await fetchAll(parameters);
    const data = Transform.fetchCollection(
      response.data,
      VesselMachinerySubCategoryWork
    );
    const meta = Transform.fetchObject(response.meta, Meta);
    return { data, meta };
  }
);

export const workAddAsync = createAsyncThunk(
  "work/addNewWork",
  async (params) => {
    const { isOnline, ...parameters } = params;

    if (!isOnline) {
      try {
        const { last_done, running_hours, instructions, remarks, file } =
          parameters;

        const vessel_machinery_sub_category_id =
          parameters["vessel_machinery_sub_category_ids[0]"];

        const profile = await db.profile.toCollection().first();
        const creator = profile
          ? `${profile.first_name} ${profile.last_name}`
          : "Offline";

        await db.queue.add({
          method: "POST",
          request_url: "works",
          data: {
            ...parameters,
            created_at: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
            updated_at: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
          },
          timestamp: new Date().toISOString(),
        });

        const id = uuid();
        await db.works
          .where("id")
          .equals(vessel_machinery_sub_category_id)
          .modify((work) => {
            work.work_history.unshift({
              id,
              last_done,
              running_hours,
              instructions,
              remarks,
              file,
              created_at: moment().format("DD-MMM-YYYY").toString(),
              creator,
            });

            const latest = work.work_history.reduceRight((prev, current) =>
              moment(prev.last_done).isAfter(current.last_done) ? prev : current
            );

            work.current_work = latest;
          });
      } catch (error) {
        console.error(error);
      }

      return new Meta({
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 20,
        previous_page_url: null,
        next_page_url: null,
        url: "",
      });
    }

    const response = await add(parameters);
    return Transform.fetchCollection(
      response.data,
      VesselMachinerySubCategoryWork
    );
  }
);

export const workCountAsync = createAsyncThunk(
  "work/countWorksByStatus",
  async (params) => {
    const { isOnline, ...parameters } = params;

    if (!isOnline) {
      const warning = await db.works
        .filter((work) => work.status === "WARNING")
        .count();

      const due = await db.works
        .filter((work) => work.status === "DUE")
        .count();

      const overdue = await db.works
        .filter((work) => work.status === "OVERDUE")
        .count();

      const jobs_done = await db.works
        .filter((work) => work.status === "JOBS DONE")
        .count();

      const dry_dock = await db.works
        .filter((work) => work.status === "DRY DOCK")
        .count();

      return {
        warning,
        due,
        overdue,
        dry_dock,
        jobs_done,
      };
    }

    const response = await count(parameters);
    return response.data;
  }
);

export const worksExportAsync = createAsyncThunk(
  "work/exportWorks",
  async (params) => {
    const works = await exportWorks(params);
    FileDownload(works, "Jobs.xls");
    return works;
  }
);

export const workHistoryExportAsync = createAsyncThunk(
  "work/exportWorkHistory",
  async (id) => {
    const workHistory = await exportWorkHistory(id);
    FileDownload(workHistory, "Jobs History.xls");
    return workHistory;
  }
);

export const fileDownloadAsync = createAsyncThunk(
  "work/downloadFile",
  async (data) => {
    const fileName = data.filename;
    const file = await downloadFile(data.path);
    FileDownload(file, fileName);
    return file;
  }
);

export const workSlice = createSlice({
  name: "work",
  initialState,
  reducers: {
    resetWork: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(workListAsync.pending, (state) => {
        state.listStatus = "loading";
      })
      .addCase(workListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        state.list = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(workListAsync.rejected, (state, action) => {
        state.listStatus = "idle";
      })
      .addCase(workAddAsync.pending, (state) => {
        state.doneListStatus = "loading";
      })
      .addCase(workAddAsync.fulfilled, (state, action) => {
        state.doneListStatus = "idle";
        state.doneList = action.payload;
      })
      .addCase(workAddAsync.rejected, (state, action) => {
        state.doneListStatus = "idle";
      })
      .addCase(workCountAsync.pending, (state) => {
        state.countStatus = "loading";
      })
      .addCase(workCountAsync.fulfilled, (state, action) => {
        state.countStatus = "idle";
        state.count = action.payload;
      })
      .addCase(workCountAsync.rejected, (state, action) => {
        state.countStatus = "idle";
      });
  },
});

export const { resetWork } = workSlice.actions;

export const workCount = (state) => state.work.count;
export const workDoneList = (state) => state.work.doneList;
export const workList = (state) => state.work.list;
export const workMeta = (state) => state.work.meta;
export const reqCountStatus = (state) => state.work.countStatus;
export const reqDoneListStatus = (state) => state.work.doneListStatus;
export const reqListStatus = (state) => state.work.listStatus;

export default workSlice.reducer;
