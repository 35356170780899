/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Inputs } from "adminlte-2-react";
import { logoutAsync } from "../../store/authSlice";
import { Entry } from "../../components";
import {
  activeVessel as defaultActiveVessel,
  setSelectedVessel,
  vesselListAsync,
  vesselList,
  vesselMeta,
} from "../../store/navbarMenuSlice";
import { useDebounce } from "../../utils/Hooks";
import Vessel from "../../core/models/Vessel";
import toast from "react-hot-toast";
import { isOnlineStatus } from "../../store/statusSlice";

const queryLimit = 5;

function NavbarMenu() {
  const isOnline = useSelector(isOnlineStatus);

  const { Text } = Inputs;

  const dispatch = useDispatch();

  const vessels = useSelector(vesselList);
  const meta = useSelector(vesselMeta);
  const activeVessel = useSelector(defaultActiveVessel);

  const [localVessels, setLocalVessels] = useState([]);
  const [localActiveVessel, setLocalActiveVessel] = useState(new Vessel());
  const [vesselSearchString, setVesselSearchString] = useState();

  const debouncedVesselSearchString = useDebounce(vesselSearchString, 1000);

  useEffect(() => {
    if (!vessels.length) {
      initVessels();
    }
  }, []);

  useEffect(() => {
    if (vessels.length && localVessels && !localVessels.length) {
      setLocalVessels(vessels);
    }
  }, [vessels, localVessels]);

  useEffect(() => {
    if (localActiveVessel && localActiveVessel.id !== activeVessel.id) {
      setLocalActiveVessel(activeVessel);
    }
  }, [localActiveVessel, activeVessel]);

  useEffect(() => {
    if (debouncedVesselSearchString !== undefined) {
      handleSearchVessel();
    }
  }, [debouncedVesselSearchString]);

  const handleSearchVesselChange = (e) => {
    setVesselSearchString(e.target.value);
  };

  const handleSearchVessel = () => {
    let params = { limit: queryLimit };
    params = !!vesselSearchString
      ? { ...params, keyword: vesselSearchString }
      : { ...params };
    initVessels(params);
  };

  const initVessels = (params = { limit: queryLimit }) => {
    dispatch(vesselListAsync({ ...params, isOnline }));
  };

  const handleLoadMoreVessels = (e) => {
    e.stopPropagation();
    let params = { limit: meta.per_page + queryLimit };
    params = !!vesselSearchString
      ? { ...params, keyword: vesselSearchString }
      : { ...params };
    initVessels({ params });
  };

  const handleLogout = () => {
    if (!isOnline) {
      toast.error("Cannot logout while offline. Please wait for connection.");

      return;
    }
    dispatch(logoutAsync());
  };

  return (
    <React.Fragment>
      <Entry
        className="tasks-menu"
        icon="fa-ship"
        label={
          isOnline
            ? activeVessel.name
            : JSON.parse(localStorage.getItem("vesselSync"))?.name || ""
        }
        header={
          <Text
            name="search"
            id="searchVesselInput"
            labelPosition="none"
            placeholder="Search vessel"
            onChange={handleSearchVesselChange}
          />
        }
        footer={
          meta.last_page !== meta.current_page && (
            <a href="javascript:" onClick={handleLoadMoreVessels}>
              see more vessels
            </a>
          )
        }
      >
        {isOnline ? (
          <>
            {vessels &&
              vessels.map((vessel) => (
                <li key={vessel.id} className="task-item">
                  <a
                    href="javascript:"
                    onClick={() => dispatch(setSelectedVessel(vessel))}
                  >
                    {vessel.name}
                  </a>
                </li>
              ))}
          </>
        ) : null}
      </Entry>
      <Entry icon="fa-power-off" onClick={handleLogout} />
    </React.Fragment>
  );
}

export default NavbarMenu;
