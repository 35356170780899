/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button } from "adminlte-2-react";
import { useDispatch, useSelector } from "react-redux";
import { activeVessel as defaultActiveVessel } from "../store/navbarMenuSlice";
import db from "../db";
import { profileData } from "../store/profileSlice";
import Transform from "../utils/Transformer";
import VesselMachineryRunningHour from "../core/models/VesselMachineryRunningHour";
import VesselMachinerySubCategoryWork from "../core/models/VesselMachinerySubCategoryWork";
import { clearAllData } from "../db/utils";
import {
  isSyncingOffline as defaultIsSyncingOffline,
  setLastOnlineSync,
  setIsSyncingOffline,
} from "../store/syncSlice";
import moment from "moment";
import initializeEcho from "../../echo";
import { fetchAll } from "../services/syncService";
import { isOnlineStatus } from "../store/statusSlice";
import CacheStatus from "./CacheStatus";

function Sync() {
  const dispatch = useDispatch();

  const activeVessel = useSelector(defaultActiveVessel);
  const profile = useSelector(profileData);
  const isSyncingOffline = useSelector(defaultIsSyncingOffline);

  const isOnline = useSelector(isOnlineStatus);
  const [dbLastUpdated, setDbLastUpdated] = useState(
    localStorage.getItem("dbLastUpdated") || null
  );

  const bulkAddRunningHours = async (runningHours) => {
    try {
      await db.runningHours.bulkAdd(runningHours);
    } catch (error) {
      console.error(error);
    }
  };

  const bulkAddWorks = async (works) => {
    try {
      await db.works.bulkAdd(works);
    } catch (error) {
      console.error(error);
    }
  };

  const bulkAddDepartments = async (departments) => {
    try {
      await db.departments.bulkAdd(departments);
    } catch (error) {
      console.error(error);
    }
  };

  const bulkAddMachineries = async (machineries) => {
    try {
      await db.machineries.bulkAdd(machineries);
    } catch (error) {
      console.error(error);
    }
  };

  const addProfile = async (profile) => {
    try {
      await db.profile.add(profile);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSyncOffline = async () => {
    dispatch(setIsSyncingOffline(true));
    let serverTime = localStorage.getItem("lastSync") || null;
    let isError = false;

    let counter = 1;
    const toastId = "syncing-offline";
    toast.loading(
      `Syncing data with vessel ${activeVessel.name}. (${counter} s)`,
      {
        id: toastId,
      }
    );

    const interval = setInterval(() => {
      counter++;
      toast.loading(
        `Syncing data with vessel ${activeVessel.name}. (${counter} s)`,
        {
          id: toastId,
        }
      );
    }, 1000);

    try {
      const data = await fetchAll({
        vessel: activeVessel.name,
      });

      clearAllData();
      serverTime = data?.profile?.server_time || serverTime;
      bulkAddRunningHours(
        Transform.fetchCollection(
          data?.running_hours,
          VesselMachineryRunningHour
        )
      );
      bulkAddWorks(
        Transform.fetchCollection(data?.works, VesselMachinerySubCategoryWork)
      );
      bulkAddMachineries(data?.machineries);
      bulkAddDepartments(data?.departments);
      addProfile(data?.profile);
    } catch (error) {
      isError = true;
      console.error(error);
    }

    clearInterval(interval);

    if (isError) {
      toast.error(
        "Failed to sync data with vessel " + activeVessel.name + ".",
        {
          id: toastId,
        }
      );
      return;
    } else {
      localStorage.setItem("vesselSync", JSON.stringify(activeVessel));
      localStorage.setItem("lastSync", serverTime);

      toast.success(
        "Sync completed. You can now work offline with vessel " +
          activeVessel.name +
          ".",
        {
          id: toastId,
        }
      );
    }

    dispatch(setIsSyncingOffline(false));
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          navigator.serviceWorker.addEventListener("message", (event) => {
            if (event.data && event.data.type === "SYNC_LOADED") {
              toast.loading("Sending your offline changes to the server.", {
                id: "syncing",
              });
            }

            if (event.data && event.data.type === "SYNC_COMPLETED") {
              toast.loading("Processing changes. Please wait.", {
                id: "syncing",
                duration: 5000,
              });
            }

            if (event.data && event.data.type === "SYNC_FAILED") {
              toast.error(
                "Failed to save your offline changes to the server. Please try again later.",
                {
                  id: "syncing",
                }
              );
            }
          });
        })
        .catch((error) => {
          console.error("Error during service worker registration:", error);
        });
    } else {
      console.log("Service workers are not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    initializeEcho();

    window.Echo.channel("database-updated-channel").listen(
      ".database-updated-event",
      (e) => {
        console.log("database-updated-channel: ", e);
        if (e.vessel_id === JSON.parse(localStorage.getItem("vesselSync")).id) {
          setDbLastUpdated(e.updated_at);
          localStorage.setItem("dbLastUpdated", e.updated_at);
        }
      }
    );

    return () => {
      window.Echo.leave("database-updated-channel");
    };
  }, []);

  useEffect(() => {
    initializeEcho();

    window.Echo.channel(`queue-updated-channel-${profile?.id}`).listen(
      ".queue-updated-event",
      (e) => {
        console.log("queue-updated-channel: ", e);
        if (e.status === "all_completed") {
          toast.success("Sync completed. Refreshing data.", {
            id: "syncing",
          });

          dispatch(setLastOnlineSync(new Date().toISOString()));
        }

        if (e.status === "error") {
          toast.error("Failed to sync data. Please try again later.");
          console.error(e.message);
        }
      }
    );

    return () => {
      window.Echo.leave("queue-updated-channel");
    };
  }, [profile?.id]);

  const isShow =
    !!profile?.permissions &&
    (profile?.permissions?.["running_hours_show"] ||
      profile?.permissions?.["jobs_show"]) &&
    profile?.department?.name === "Crewing";

  return (
    <>
      {isShow && (
        <div>
          <Button
            className="d-flex btn-block"
            type="primary"
            text={isSyncingOffline ? "Syncing data..." : "Sync Offline"}
            onClick={handleSyncOffline}
            disabled={isSyncingOffline || !isOnline}
          />
          <p
            style={{
              marginTop: "10px",
              color: "white",
            }}
          >
            Status:{" "}
            {localStorage.getItem("lastSync") ? (
              <>
                {!dbLastUpdated ? (
                  <strong> Data is up-to-date</strong>
                ) : (
                  <>
                    {moment(dbLastUpdated).isBefore(
                      localStorage.getItem("lastSync")
                    ) ? (
                      <strong> Data is up-to-date</strong>
                    ) : (
                      <strong
                        style={{
                          color: "red",
                        }}
                      >
                        Data is not up-to-date
                      </strong>
                    )}
                  </>
                )}
              </>
            ) : (
              <strong> No offline data found</strong>
            )}
            <br />
            {localStorage.getItem("vesselSync") ? (
              <>
                Vessel:{" "}
                <strong>
                  {JSON.parse(localStorage.getItem("vesselSync"))?.name || ""}
                </strong>
              </>
            ) : null}
          </p>
          <CacheStatus />
        </div>
      )}
    </>
  );
}

export default Sync;
