import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastOnlineSync: null,
  isSyncingOffline: false,
};

export const syncSlice = createSlice({
  name: "sync",
  initialState,
  reducers: {
    setLastOnlineSync: (state, action) => {
      state.lastOnlineSync = action.payload;
    },
    setIsSyncingOffline: (state, action) => {
      state.isSyncingOffline = action.payload;
    },
  },
});

export const { setLastOnlineSync, setIsSyncingOffline } = syncSlice.actions;

export const lastOnlineSync = (state) => state.sync.lastOnlineSync;
export const isSyncingOffline = (state) => state.sync.isSyncingOffline;

export default syncSlice.reducer;
