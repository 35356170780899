export default function syncData() {
  // Retrieve the access token from local storage
  const accessToken = localStorage.getItem("accessToken");

  // Retrieve the API URL from environment variables
  const apiUrl = process.env.REACT_APP_API_URL;

  navigator.serviceWorker.ready.then((registration) => {
    // Pass the access token and API URL to the service worker using postMessage
    registration.active.postMessage({
      type: "SET_SYNC_DATA",
      accessToken: accessToken,
      apiUrl: apiUrl,
    });

    // Register for sync event
    registration.sync.register("sync-data").catch((err) => {
      console.error("Sync registration failed:", err);
    });
  });
}
