import { useEffect } from "react";
import toast from "react-hot-toast";

const CacheStatus = () => {
  useEffect(() => {
    const checkCache = async () => {
      try {
        const cache = await caches.open("pms-cache-v1");
        if (!cache) {
          return;
        }

        if (!localStorage.getItem("isDashboardCached")) {
          const dashboardCached = await cache.match("/");
          const dashboardStatus = dashboardCached ? true : false;
          if (dashboardStatus) {
            localStorage.setItem("isDashboardCached", true);
            toast.success("Dashboard is ready for offline use");
          }
        }

        if (!localStorage.getItem("isRunningHoursCached")) {
          const runningHoursCached = await cache.match("/running-hours");
          const runningHoursStatus = runningHoursCached ? true : false;
          if (runningHoursStatus) {
            localStorage.setItem("isRunningHoursCached", true);
            toast.success("Running hours is ready for offline use");
          }
        }

        if (!localStorage.getItem("isWorksCached")) {
          const worksCached = await cache.match("/works");
          const worksStatus = worksCached ? true : false;
          if (worksStatus) {
            localStorage.setItem("isWorksCached", true);
            toast.success("Update jobs is ready for offline use");
          }
        }
      } catch (error) {
        console.error("Cache check failed:", error);
      }
    };

    checkCache();
  }, []);

  return null;
};

export default CacheStatus;
