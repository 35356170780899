import Http from "../utils/Http";

export function fetchAll(params) {
  return new Promise((resolve, reject) => {
    Http.get("running-hours", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function add(data) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  formData.append("_method", "POST");
  return new Promise((resolve, reject) => {
    Http.post("running-hours", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        reject(err);
      });
  });
}

export function exportRunningHours(params) {
  return new Promise((resolve, reject) => {
    Http.get("running-hours/export", { params, responseType: "blob" })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function exportRunningHoursHistory(id) {
  return new Promise((resolve, reject) => {
    Http.get(`running-hours/${id}/export`, { responseType: "blob" })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
