import Dexie from "dexie";

const db = new Dexie("pms_db");

db.version(1).stores({
  runningHours:
    "++id, vessel.name, machinery.department.name, machinery.name, machinery.code_name, current_running_hour.created_at, current_running_hour.running_hours",
  works:
    "++id, code, department, machinery, status, current_work.created_at, sub_category.name, description.name, interval.name, current_work.instructions, current_work.remarks",
  departments: "++id, name",
  machineries: "++id, name",
  profile: "++id",
  queue: "++id, timestamp",
});

export default db;
