import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivateLayout from "./PrivateLayout";
import PublicLayout from "./PublicLayout";
import { authenticated } from "../store/authSlice";
import { profileAsync, profileData } from "../store/profileSlice";
import { isOnlineStatus, setIsOnline, statusAsync } from "../store/statusSlice";
import { getStatus } from "../services/statusService";
import toast from "react-hot-toast";
import db from "../db";
import syncData from "../utils/SyncData";

function Layout({ children }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const profile = useSelector(profileData);
  const isOnline = useSelector(isOnlineStatus);
  const retryInterval = 5000;

  const initProfile = useCallback(() => {
    dispatch(profileAsync({ isOnline }));
  }, [dispatch, isOnline]);

  const initStatus = useCallback(() => {
    dispatch(statusAsync());
  }, [dispatch]);

  const getServerStatus = useCallback(async () => {
    try {
      const isOnline = await getStatus();
      if (isOnline) {
        const queueLength = await db.queue.count();
        if (queueLength > 0) {
          syncData();
        }

        dispatch(setIsOnline(true));
        toast.success("You're back online!");
      }
    } catch (error) {
      console.error("Can't connect to the server");
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !profile?.id) {
      initProfile();
    }
  }, [isAuthenticated, profile, initProfile]);

  useEffect(() => {
    const handleNetworkChange = () => {
      initStatus();
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    initStatus(); // Initial status check on mount

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, [initStatus]);

  useEffect(() => {
    let retryTimer;
    if (!isOnline) {
      retryTimer = setInterval(() => {
        getServerStatus();
      }, retryInterval);
    }

    // Cleanup interval on component unmount or when isOnline becomes true
    return () => {
      if (retryTimer) clearInterval(retryTimer);
    };
  }, [isOnline, getServerStatus, retryInterval]);

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <PrivateLayout>{children}</PrivateLayout>
      ) : (
        <PublicLayout>{children}</PublicLayout>
      )}
    </React.Fragment>
  );
}

export default Layout;
